'use client';

import React, { useEffect, useState } from 'react';
import ArrowDownLongIcon from '@/src/assets/svg/arrow-down-long.svg';
import ArrowUpLongIcon from '@/src/assets/svg/arrow-up-long.svg';
import {
  AboutUsInitialTitle1,
  AboutUsInitialTitle2,
  HomepageArrowUpIcon,
  HomepageInitialContainer,
  HomepageLearnMore,
  HomepageLearnMoreText,
  HomepagePrimaryTextContainer,
  HomepageSecondaryText,
} from '@/src/styles/homepage-styles';
import Button from '@/src/components/ui/Button';
import { anton } from '@/src/fonts';

const HomepageInitial: React.FC = () => {
  const [isTouchDevice, setTouchDevice] = useState(false);

  useEffect(() => {
    setTouchDevice(
      'ontouchstart' in window ||
        navigator?.maxTouchPoints > 0 ||
        window.matchMedia('(pointer: coarse)')?.matches,
    );
  }, []);

  return (
    <HomepageInitialContainer className="initial-first-section">
      <HomepagePrimaryTextContainer>
        <AboutUsInitialTitle1 className={`initial-image1 ${anton.className}`}>Exceeding</AboutUsInitialTitle1>
        <AboutUsInitialTitle2 className={`initial-image2 ${anton.className}`}>
          All Expectations
        </AboutUsInitialTitle2>
      </HomepagePrimaryTextContainer>
      <HomepageSecondaryText className={'initial-subtext'}>
        We leverage <span style={{ color: '#D5B4FF' }}>strategy, design</span> and{' '}
        <span style={{ color: '#D5B4FF' }}>software engineering</span> to transform enterprises and startups
        into market-leading digital organizations
      </HomepageSecondaryText>
      <HomepageLearnMore>
        <HomepageLearnMoreText style={{ display: isTouchDevice ? 'none' : 'initial' }}>
          Scroll
        </HomepageLearnMoreText>
        <HomepageLearnMoreText style={{ display: !isTouchDevice ? 'none' : 'initial' }}>
          Swipe
        </HomepageLearnMoreText>
        <Button
          className={'initial-more-button'}
          $buttonType={'outlined'}
          style={{
            transition: 'height 1s, opacity 1s',
            minHeight: 72,
            width: 42,
            padding: '0',
            pointerEvents: 'none',
          }}
        >
          <HomepageArrowUpIcon
            className={'initial-arrow'}
            src={isTouchDevice ? ArrowUpLongIcon : ArrowDownLongIcon}
            alt={'homepage-arrow-down-long'}
          />
        </Button>
      </HomepageLearnMore>
    </HomepageInitialContainer>
  );
};

export default HomepageInitial;
